<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader
            :title="headercontent.title"
            :addlink="headercontent.addlink"
          />
        </v-col>
        <v-col md="12">
          <h3 class="filtertitle">Filter {{ headercontent.title }}</h3>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="form-section"
          >
            <v-row>
              <v-col cols="3">
                <v-select
                  :items="roles"
                  v-model="role"
                  label="Role"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" class="submitbtn">
                <v-btn
                  :disabled="!valid"
                  class="submitbutton btn-dark"
                  @click="validate"
                >
                  Filter {{ headercontent.title }}
                </v-btn>
                <!-- <v-progress-circular
         
                  indeterminate
                ></v-progress-circular> -->
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col md="12">
          <v-simple-table class="table" v-if="!allLoadingAuth">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">S/N</th>
                  <th class="text-left">Name</th>
                  <th class="text-left">Phone</th>
                  <th class="text-left">Email</th>
                  <th class="text-left">Role</th>
                  <!-- <th class="text-right" colspan="2">
                    Action
                  </th> -->
                </tr>
              </thead>
              <tbody v-if="allUser.results">
                <tr v-for="(item, index) in allUser.results.list" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.fullname }}</td>
                  <td>{{ item.phone }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.role }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div v-if="allLoadingAuth">
            <v-skeleton-loader type="image" height="200"></v-skeleton-loader>
            <v-skeleton-loader type="image" height="200"></v-skeleton-loader>
          </div>
          <ul
            class="pagination-button"
            v-if="allUser.results != undefined && !allLoadingAuth"
          >
            <li>
              Page :
              <span v-if="allUser.results.pagination.next">{{
                (allUser.results.pagination.next - 1) * this.limit
              }}</span
              ><span v-if="!allUser.results.pagination.next">{{
                allUser.results.pagination.total
              }}</span>
              -
              {{ allUser.results.pagination.total }}
            </li>
            <li>
              <div>
                <v-btn
                  v-if="allUser.results.pagination.previous"
                  @click="prevPage(allUser.results.pagination.previous)"
                  class="btn-dark"
                  >Prev</v-btn
                >

                <v-btn
                  v-if="allUser.results.pagination.next"
                  @click="nextPage(allUser.results.pagination.next)"
                  class="btn-dark"
                  >Next</v-btn
                >
              </div>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "User",
  components: { PageHeader },
  data: () => ({
    headercontent: {
      title: "User List",
      addlink: "/dashboard/user/add",
    },
    title: "",
    adsposition: "",
    valid: true,
    limit: 100,
    skip: 1,
    filter: {},
    roles: ["admin", "user", "employee"],
    role: "user",
  }),
  computed: {
    ...mapGetters(["allUser", "allLoadingAuth"]),
  },
  methods: {
    ...mapActions(["getUsers"]),
    validate() {
      this.filter.role = this.role;
      this.getUsers({ limit: this.limit, skip: this.skip, role: this.role });
    },
    nextPage(data) {
      this.getUsers({
        limit: this.limit,
        skip: data,
      });
    },
    prevPage(data) {
      this.getUsers({
        limit: this.limit,
        skip: data,
      });
    },
  },
  created() {
    this.getUsers({ limit: this.limit, skip: this.skip });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.form-section {
  padding: 15px 0;
  .col {
    padding: 0 10px;
  }
}
.filtertitle {
  margin-bottom: 10px;
}
.btn-dark {
  background: $tagcolor !important;
  color: $white;
  margin-top: -10px;
}

.table {
  box-shadow: 0px -1px 5px 2px rgba(0, 0, 0, 0.1);

  thead {
    border-bottom: 2px solid $menucolor;
    background: $tagcolor;
  }
  th {
    font-size: $normal_lfont !important;
    color: $white !important;
  }
}
.pagination-button {
  display: flex;
  list-style: none;
  padding-left: 0;
  justify-content: space-between;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
  .btn-dark {
    margin-top: 0;
    margin-left: 10px;
  }
}
.table-heading,
.pagination-button {
  border: 1px solid $menucolor;
  margin-bottom: 0;
  padding: 10px;
  align-items: center;
}
.icons-action {
  margin: 10px;
  font-size: $iconfont;
}
</style>
